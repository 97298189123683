<template>
  <div class="index-container">
    <div class="home-top">
      <!-- <img v-if="homeTopUrl" :src="homeTopUrl | rdv" /> -->
      <div class="swiper-out-wrapper">
        <swiper-ete v-if="bannerlist && bannerlist.length" :swiper-list="bannerlist" wiper-name="banner" />
      </div>
      <div class="seawave">
        <sea-wave />
      </div>
    </div>
    <div class="home-content">
      <div class="home-menu">
        <el-row class="menu-list">
          <el-col :md="6" :sm="6" :xs="12">
            <div class="menu-item" @click="menuClick('reg')">
              <img class="overimg" :src="(ossBaseUrl + regIcon) | rdv" />
              <div class="title">参会/参观注册</div>
            </div>
          </el-col>
          <el-col :md="6" :sm="6" :xs="12">
            <div class="menu-item" @click="menuClick('exh')">
              <img class="overimg" :src="(ossBaseUrl + exhIcon) | rdv" />
              <div class="title">展商中心</div>
            </div>
          </el-col>
          <el-col :md="6" :sm="6" :xs="12">
            <div class="menu-item" @click="menuClick('apply')">
              <img class="overimg" :src="(ossBaseUrl + applyIcon) | rdv" />
              <div class="title">展位申请</div>
            </div>
          </el-col>
          <el-col :md="6" :sm="6" :xs="12">
            <div class="menu-item">
              <img class="overimg" :src="(ossBaseUrl + appqrIcon) | rdv" />
              <div class="title">三新展小程序</div>
            </div>
          </el-col>
        </el-row>
        <div class="menu-list"></div>
      </div>
      <div class="home-section">
        <div class="home-show">
          <tip-ete :title="'关于展会'" />
          <el-row :gutter="20">
            <el-col :md="12">
              <div class="des">
                <div v-html="aboutus.content"></div>
                <div class="des-txt" v-html="aboutus.abstract"></div>
                <!-- <div class="des-txt">
                  <span>
                    活动时间：2023年2月27-28日
                    <br />
                    活动地点： 杭州国际博览中心 3D 展厅
                    <br />
                    展览规模： 10,000 平方米
                    <br />
                    <br />
                    主办单位：
                    <br />
                    杭州市萧山钱江世纪城管理委员会
                    <br />
                    中国会展经济研究会
                    <br />
                    <br />
                    承办单位：
                    <br />
                    北京华恺国际展览有限公司
                    <br />
                    杭州国际博览中心会展策划有限公司
                    <br />
                    <br />
                    协办单位：
                    <br />
                    浙商总会会展业委员会
                    <br />
                    广东会展组展企业协会
                  </span>
                </div> -->
              </div>
              <div class="more">
                <div class="tool pdr20" @click="metomore()">
                  <div class="txt">更多</div>
                  <img src="https://iscfiles.oss-cn-shanghai.aliyuncs.com/tmp/ETE/web/more.png" />
                </div>
              </div>
            </el-col>
            <el-col class="home-show-right" :md="12">
              <div class="swiper-out-wrapper">
                <swiper-ete v-if="aboutus.list && aboutus.list.length" :swiper-list="aboutus.list" wiper-name="gyzh" />
              </div>
            </el-col>
          </el-row>
        </div>

        <!-- <div class="card"></div> -->
      </div>
      <div class="home-section agenda-section">
        <div class="home-item">
          <tip-ete :icon="2" :title="'峰会议程'" />
          <el-tabs v-model="agendaTabActiveSID" class="agenda-tabs" @tab-click="handleTabClick">
            <el-tab-pane v-for="(item, index) in agendaTabItems" :key="index" class="agenda-tabitem" :label="item.SName"
              :name="item.SID">
              <template v-if="agendaList && agendaList.length">
                <agenda-list :list="agendaList" />
              </template>
              <template v-else>
                <div class="agenda-nodata center">敬请期待</div>
              </template>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <div class="home-section home-bg pdt20">
        <div class="home-item">
          <new-list v-if="newsList.length" :list="newsList" :stype="newskey" :title="'展会新闻'" @articleClick="articleClick"
            @moreEvent="moreEvent" />
        </div>
      </div>
      <!-- <div class="home-section home-bg">
        <div class="home-item">
          <new-list
            v-if="zxfbList.length"
            :list="zxfbList"
            :stype="zxfbkey"
            :title="'资讯发布'"
            @articleClick="articleClick"
            @moreEvent="moreEvent"
          />
        </div>
      </div> -->
      <div class="home-section home-ep">
        <div class="home-item">
          <tip-ete v-if="epListtest && epListtest.length" :title="'往届参展企业'" />
          <!-- <image-list :list="epList" /> -->
          <div class="swiper-out-wrapper">
            <!-- <swiper-ete-multi :swiper-list="epList" /> -->
            <swiper-ete v-if="epListtest && epListtest.length" bullet="bottom" :swiper-list="epListtest"
              wiper-name="zsqy" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { rdv, DealContent, SetLogoPathNames } from '@/utils/index'
import { getArticleByType, tagList, getMenuTreeByType, getMainMenu } from '@/api/site'
import { registerUrl, exhcenterUrl } from '@/config'//exhApplyUrl
import {
  _GetArticleListByType,
  _GetTags,
  _GetArticlePageListByType,
  GetFinalAgendaList,
  _GetNextArticleListBySID,
} from '@/utils/deal'
export default {
  name: 'Index',
  filters: {
    rdv(url) {
      return rdv(url)
    },
  },
  data() {
    return {
      regIcon: 'regicon.png',
      appqrIcon: 'appqr.png',
      exhIcon: 'exhicon.png',
      applyIcon: 'apply.png',
      bannerlist: [],
      epListtest: [
        {
          src: 'https://iscfiles.oss-cn-shanghai.aliyuncs.com/tmp/ETE/web/banner/exh01.png',
        },
        {
          src: 'https://iscfiles.oss-cn-shanghai.aliyuncs.com/tmp/ETE/web/banner/exh02.png',
        },
        {
          src: 'https://iscfiles.oss-cn-shanghai.aliyuncs.com/tmp/ETE/web/banner/exh03.png',
        },
        {
          src: 'https://iscfiles.oss-cn-shanghai.aliyuncs.com/tmp/ETE/web/banner/exh04.png',
        },
        {
          src: 'https://iscfiles.oss-cn-shanghai.aliyuncs.com/tmp/ETE/web/banner/exh05.png',
        },
        {
          src: 'https://iscfiles.oss-cn-shanghai.aliyuncs.com/tmp/ETE/web/banner/exh06.png',
        },
        {
          src: 'https://iscfiles.oss-cn-shanghai.aliyuncs.com/tmp/ETE/web/banner/exh07.png',
        },
        {
          src: 'https://iscfiles.oss-cn-shanghai.aliyuncs.com/tmp/ETE/web/banner/exh08.png',
        },
      ],
      aboutus: {
        list: [],
        title: '',
        content: '',
        logo: '',
        ArticleID: '',
        abstract: '',
      },
      ycSID: 'VP',
      newsList: [],
      zxfbList: [],
      epList: [],
      agendaList: [],
      agendaTabItems: [],
      agendaTabActiveSID: '',
      aboutuskey: 'GYZHLIST',
      newskey: 'ZHXWLIST',
      zxfbkey: 'ZXFBLIST',
      epkey: 'CZQYLIST',
      agendaKey: 'YCLBLIST',
      yqtzType: 'YQTZ',
      MenueList: []
    }
  },
  computed: {
    ...mapGetters({
      homeTopUrl: 'settings/homeTopUrl',
      ossBaseUrl: 'settings/ossBaseUrl',
    }),
  },
  async created() {
    // await this.getYQTZ()
    await this.getBanners()
    await this.getAbout()
    await this.getNewsList()
    // await this.getZXFBList()
    // await this.getEnterpriseList()
    // await this.getAgendaList()
    await this.getAgendaMenu()
    await this.getMenue()
  },
  methods: {
    async getBanners() {
      const param = {
        tagkey: 'HOMEBANNERPC',
      }
      const res = await tagList(param)
      if (res && res.data) {
        let detail = res.data
        let imageArray = []
        if (detail) {
          detail.forEach((item) => {
            if (item.ImageUrl) imageArray.push({ src: item.ImageUrl })
          })
        }
        this.bannerlist = imageArray
      }
    },
    async getMenue() {

      getMainMenu().then((res) => {
        if (res && res.data) {
          this.MenueList = res.data
        }
      })
    },
    menuClick(type) {
      switch (type) {
        case 'reg':
          window.open(registerUrl)
          break
        case 'exh':
          window.open(exhcenterUrl)
          break
        case 'apply':
          for (var i = 0; i < this.MenueList.length; i++) {
            if (this.MenueList[i].SID == "GK" && this.MenueList[i].Link) {
              window.open(this.MenueList[i].Link)
            }

          }
          //window.open(exhApplyUrl)
          break
      }
    },
    async getYQTZ() {
      let res = await getArticleByType({
        SType: this.yqtzType,
      })
      if (res && res.data && res.data.Contents) {
        this.$baseAlert(res.data.Contents, '延期通知')
      }
    },
    async getAbout() {
      let param = {
        SType: this.aboutuskey,
      }
      let res = await getArticleByType(param)
      if (res && res.data) {
        let detail = res.data
        this.aboutus.ArticleID = detail.ArticleID
        this.aboutus.title = detail.Title || ''
        this.aboutus.abstract = detail.AbstractEN || ''
        if (detail.ContentsEN) {
          this.aboutus.content = DealContent(detail.ContentsEN || '')
        }
        if (detail.LogoPath && detail.OssImagePath) {
          this.aboutus.list = SetLogoPathNames(detail)
        }
      }
    },
    async getEnterpriseList() {
      await _GetTags(this.epkey, (list) => {
        var testlist = []
        for (var i = 0; i < 20; i++) {
          testlist = testlist.concat(list)
        }
        this.epList = testlist
      })
    },
    async getNewsList() {
      await _GetArticlePageListByType(this.newskey, 1, 4, (data) => {
        if (data && data.list) {
          this.newsList = data.list
        }
      })
    },
    async getZXFBList() {
      await _GetArticleListByType(this.zxfbkey, (list) => {
        this.zxfbList = list
      })
    },
    async getAgendaMenu() {
      const res = await getMenuTreeByType({ SType: this.agendaKey })
      if (res && res.data) {
        const menus = res.data.length > 0 ? res.data[0].Children : null
        if (menus) {
          const tabitems = []
          let firstsid = ''
          menus.forEach((item, index) => {
            console.log(index)
            if (index == 0) firstsid = item.SID
            tabitems.push({ SName: item.SName, SID: item.SID })
          })
          this.agendaTabItems = tabitems
          this.agendaTabActiveSID = firstsid
          if (this.agendaTabActiveSID) {
            this.handleTabClick()
          }
        }
      }
    },
    async handleTabClick() {
      if (this.agendaTabActiveSID) {
        await _GetNextArticleListBySID(this.agendaTabActiveSID, (list) => {
          this.setAgendaList(list)
        })
      }
    },
    setAgendaList(list) {
      if (list) {
        // let sortList = DealAgendaData(list)
        // console.log('sortList', sortList)
        // // sortList.splice(1, 0, ...sortList.splice(2, 1))
        // let newGroupList = []
        // let totalCount = sortList.length
        // let totalIndex = 0
        // if (totalCount > 0) {
        //   if (totalCount <= 2) totalIndex = 1
        //   else if (totalCount / 2 == 0) totalIndex = totalCount / 2
        //   else totalIndex = totalCount / 2 + 1
        // }

        // for (let i = 1; i <= totalCount; i++) {
        //   newGroupList.push({
        //     rowid: i,
        //     list: sortList.splice(0, totalIndex),
        //   })
        // }
        // console.log('newGroupList', newGroupList)
        // newGroupList.forEach((o1) => {
        //   o1.list.forEach((o2) => {
        //     let tempList = []
        //     o2.list.forEach((o3) => {
        //       let exist = tempList.find(
        //         (i) =>
        //           i.ReleaseTimeText == o3.ReleaseTimeText &&
        //           o3.ReleaseTimeText
        //       )
        //       if (exist) {
        //         exist.list.push(o3)
        //       } else {
        //         tempList.push({
        //           ReleaseTimeText: o3.ReleaseTimeText,
        //           list: [o3],
        //         })
        //       }
        //     })
        //     // console.log('tempList', tempList)
        //     o2['newlist'] = tempList
        //   })
        // })
        this.agendaList = GetFinalAgendaList(list)
      }
    },
    async getAgendaList() {
      // await _GetArticleListBySID(this.ycSID, (list) => {
      //   this.agendaList = list ? DealAgendaData(list) : list
      //   console.log(this.agendaList)
      // })
      // await _GetArticleListByType(this.agendaKey, (list) => {
      //   console.log(list)
      //   this.setAgendaList(list)
      //   // if (list) {
      //   //   let sortList = DealAgendaData(list)
      //   //   console.log(sortList)
      //   //   // sortList.splice(1, 0, ...sortList.splice(2, 1))
      //   //   let newGroupList = []
      //   //   let totalCount = sortList.length
      //   //   let totalIndex = 0
      //   //   if (totalCount > 0) {
      //   //     if (totalCount <= 2) totalIndex = 1
      //   //     else if (totalCount / 2 == 0) totalIndex = totalCount / 2
      //   //     else totalIndex = totalCount / 2 + 1
      //   //   }
      //   //   for (let i = 1; i <= totalIndex; i++) {
      //   //     newGroupList.push({ rowid: i, list: sortList.splice(0, 2) })
      //   //   }
      //   //   console.log('newGroupList', newGroupList)
      //   //   newGroupList.forEach((o1) => {
      //   //     o1.list.forEach((o2) => {
      //   //       let tempList = []
      //   //       o2.list.forEach((o3) => {
      //   //         let exist = tempList.find(
      //   //           (i) =>
      //   //             i.ReleaseTimeText == o3.ReleaseTimeText &&
      //   //             o3.ReleaseTimeText
      //   //         )
      //   //         if (exist) {
      //   //           exist.list.push(o3)
      //   //         } else {
      //   //           tempList.push({
      //   //             ReleaseTimeText: o3.ReleaseTimeText,
      //   //             list: [o3],
      //   //           })
      //   //         }
      //   //       })
      //   //       console.log('tempList', tempList)
      //   //       o2['newlist'] = tempList
      //   //     })
      //   //   })
      //   //   this.agendaList = newGroupList
      //   //   console.log('this.agendaList', this.agendaList)
      //   // }
      // })
    },
    moreEvent(e) {
      console.log(e)
      this.$router.push({
        path: 'news',
      })
    },
    toMore() {
      // this.$navigateTo(
      //   `/pages/articleList/articleList?type=${this.showType}&menuid=${this.menuid}&title=${e.C_TITLE}`
      // )
    },
    metomore() {
      // this.$navigateTo(
      //   `/pages/articleDetail/articleDetail?isMultImage=1&id=${this.aboutus.ArticleID}&title=${this.aboutus.title}`
      // )
      this.$router.push({
        path: 'detail',
        query: {
          isMultImage: 1,
          sd: 1,
          id: this.aboutus.ArticleID,
          title: this.aboutus.title,
        },
      })
    },
    articleClick(e) {
      console.log('articleClick', e)
      if (e.OutLink) {
        window.open(e.OutLink)
      } else {
        this.$router.push({
          path: 'detail',
          query: {
            id: e.ArticleID,
            title: e.Title,
          },
        })
      }
    },
  },
}
</script>
<style lang="scss">
.el-message-box__title {
  text-align: center;
}

.seawave {
  margin-top: -150px;
  position: relative;
  z-index: 10;
}

.clear-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.index-container {
  .home-top {

    // > img {
    //   width: 100%;
    // }
    .swiper-out-wrapper {
      max-height: 600px;

      .swiper-slide>img {
        width: 100%;
        object-fit: cover;
        max-height: 600px;
      }
    }
  }

  .home-content {
    .home-menu {
      width: 100%;

      .menu-list {
        margin: auto;
        width: 90%;

        .menu-item {
          text-align: center;
          margin-top: 20px;

          >img {
            height: 180px;
            width: 180px;
          }

          .title {
            font-size: 20px;
            margin-top: 10px;
          }
        }
      }
    }
  }
}

.home-section {
  padding-top: 30px;

  .home-show,
  .home-item {
    width: 90%;
    margin: 0 auto;

    .home-show-right {
      margin-top: 15px;
    }

    .yc-wrapper {
      padding: 20px 0px;
    }

    .des {
      text-align: left;

      .des-txt {
        line-height: 1.5;
        white-space: pre-line;
      }
    }
  }
}

.home-ep {
  padding-bottom: 30px;

  .swiper-out-wrapper {
    padding: 20px 0px;
  }
}

.home-bg {
  background-color: $base-color-background;
}
</style>
